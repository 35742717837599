<template>
    <div class="page page-bg">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('找回密码')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper-bg">
            <div class="login-head">
                <van-popover v-model="showPopover" trigger="click">
                    <div class="filter-dropdown">
                        <van-radio-group v-model="radio">
                            <van-cell :title="$t('邮箱')" clickable @click="radio = '1'" v-if="mail_register == 1">
                                <template #right-icon>
                                    <van-radio name="1" checked-color="#20b759" />
                                </template>
                            </van-cell>
                            <van-cell-group>
                                <van-cell :title="$t('手机号')" clickable @click="radio = '2'">
                                    <template #right-icon>
                                        <van-radio name="2" checked-color="#20b759"/>
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                    <template #reference>
                        <div class="btn l-button">
                            <span>{{labelV}}</span><span class="iconfont icon-down1"></span>
                        </div>
                    </template>
                </van-popover>
            </div>
            <van-form class="bs-form login-form">
                <van-field v-model="username" :placeholder="$t('请输入用户名')"/>
                <van-field v-model="phone" center :placeholder="$t('请输入你的手机号码')" type="digit" v-if="radio == '2'" >
                    <template #label>
                        <div class="sw-label" @click="showPicker = true">
                            <span class="lan-n">{{defaultCountry.value}}</span>
                            <span class="iconfont icon-down1"></span>
                        </div>
                    </template>
                </van-field>
                <van-field v-model="phone" center :placeholder="$t('请输入Email')" v-else />

                <van-field v-model="code"
                           :placeholder="$t('请输入验证码')">
                    <template #button>
                        <van-button
                               type="orange" size="small" class="get-code" :label="$t('验证码')"
                                :disabled="timeout != 0"
                                @click="handleClickSendCode"
                        >{{timeout > 0 ? timeout : $t('获取验证码')}}
                        </van-button>
                    </template>
                </van-field>
                <van-field v-model="password" center :placeholder="$t('请输入密码')" v-if="sWpwd">
                    <template #button>
                        <van-icon name="eye" size="20px" color="#fb5b5b" @click.native="sWpwd = false"/>
                    </template>
                </van-field>
                <van-field type="password" v-model="password" center :placeholder="$t('请输入密码')" v-else>
                    <template #button>
                        <van-icon name="closed-eye" size="20px" color="#fb5b5b" @click.native="sWpwd = true"/>
                    </template>
                </van-field>

                <van-field v-model="password2" center :placeholder="$t('请输入确认密码')" v-if="sWpwd">
                    <template #button>
                        <van-icon name="eye" size="20px" color="#fb5b5b" @click.native="sWpwd = false"/>
                    </template>
                </van-field>
                <van-field type="password" v-model="password2" center :placeholder="$t('请输入确认密码')" v-else>
                    <template #button>
                        <van-icon name="closed-eye" size="20px" color="#fb5b5b" @click.native="sWpwd = true"/>
                    </template>
                </van-field>
            </van-form>
        </div>
        <div class="btn-foot-space">
            <van-button @click="handleClickOk" color="linear-gradient(to right, #3270e0, #3653ea)" block>{{$t('重新设置密码')}}</van-button>
        </div>
        <van-popup v-model="show_captcha" position="center" style="border-radius: 10px">
            <div style="padding:20px 15px;">
                <img :src="captcha_img" class='captchaimg' @click="getCaptcha">
                <van-field
                        v-model="captcha_code"
                        name="captcha_code"
                        :placeholder="$t('输入验证码')"
                />
                <van-button style="height: 30px" @click="sendSms">{{$t('确认')}}</van-button>
            </div>
        </van-popup>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="allCountries"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                    value-key="text"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            >
            </van-picker>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                radio: '2',
                labelV: this.$t('手机号'),
                sWpwd: false,
                showPopover: false,
                showPicker: false,
                show: false,
                username: "",
                phone: "",
                password: "",
                password2: "",
                timeout_limit: 60,
                timeout: 0,
                captcha_key: "",
                captcha_code: "",
                captcha_img: '',
                code: '',
                show_captcha: false,
                sms_captcha: 0,
                mail_captcha: 0,
                mail_register: 0,
                defaultCountry: '+92',
                allCountries: [
                    {text: 'Pakistan (‫پاکستان‬‎) +92', value: '+92'},
                    {text: 'India (भारत) +91', value: '+91'},
                    {text: 'Bangladesh (বাংলাদেশ) +880', value: '+880'},
                    {text: 'United States +1', value: '+1'},
                    {text: 'Australia +61', value: '+61'},
                    {text: 'Singapore +65', value: '+65'},
                    {text: 'United Kingdom +44', value: '+44'},
                    {text: 'Japan +81', value: '+81'},
                    {text: 'South Korea (대한민국) +82', value: '+82'},
                    {text: 'Saudi Arabia (‫المملكة العربية السعودية‬‎) +966', value: '+966'},
                    {text: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎) +971', value: '+971'},
                ]
            }
        },
        methods: {
            onConfirm(value) {
                this.showPicker = false;
                this.defaultCountry = value;
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            handleClickSendCode() {
                if(this.username == "" || this.username.trim() == ''){
                    this.$toast.fail(this.$t('请输入用户名'));
                    return ;
                }
                if(this.phone == "" || this.phone.trim() == ''){
                    if(this.radio == '1'){
                        this.$toast.fail(this.$t('请输入Email'));
                    }else{
                        this.$toast.fail(this.$t('请输入手机号码'));
                    }
                    return ;
                }

                if(this.radio == '1'){
                    this.sendMail();
                }else{
                    this.sendSms();
                }
            },
            sendSms(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }

                this.send_flag = true;
                this.$axios.post(
                    this.$api_url.send_code,
                    this.$qs.stringify({
                        is_forget: 1,
                        phone: this.phone,
                        country: this.defaultCountry,
                        captcha: this.captcha_code,
                        key: this.captcha_key,
                    })
                )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.timerInit(this.timeout_limit);
                        } else {
                            this.captcha_code = '';
                            this.$toast.fail(res.data.msg);
                        }
                        this.send_flag = false;
                    }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            sendMail(){
                this.$toast.loading();

                if(this.timeout > 0 || this.send_flag){
                    return ;
                }

                this.send_flag = true;
                this.$axios.post('/send_mail',
                    this.$qs.stringify({email: this.phone})
                ).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        this.timerInit(this.timeout_limit);
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                    this.send_flag = false;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                    this.send_flag = false;
                });
            },
            timerInit(s) {
                this.timeout = s;
                let timer = setInterval(() => {
                    if (this.timeout <= 0) {
                        clearInterval(timer);
                        localStorage.removeItem("last_timer");
                        return;
                    }
                    this.timeout -= 1;
                    localStorage.setItem("last_timer", this.timeout);
                }, 1000);
            },
            handleClickOk() {
                let loading = this.$toast.loading();
                this.$axios.post(this.$api_url.forget, this.$qs.stringify({
                    username: this.username,
                    phone: this.phone,
                    code: this.code,
                    country: this.defaultCountry,
                    password: this.password,
                    is_mail: (this.radio == '1' ? 1 : 0)
                })).then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                    } else {
                        this.$toast.fail(res.data.msg);
                    }
                }).catch((ex) => {
                    this.$toast.fail(ex.message);
                });
            },
            getCountryCode(){
                this.$axios
                    .get('/country_code?type=find')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.allCountries = res.data.data.list;
                            this.defaultCountry = res.data.data.default;
                        }
                    });
            }
        },
        mounted() {
            this.sms_captcha = localStorage.getItem('app.sms_captcha') || 0;
            this.mail_register = localStorage.getItem('app.mail_register') || 0;
            this.mail_captcha = localStorage.getItem('app.mail_captcha') || 0;
            let time = localStorage.getItem("last_timer");
            if (time != null && time >= 0) {
                this.timerInit(time);
            }
        },
        created() {
            this.getCountryCode();
        },
        watch:{
            username(nv, ov){
                this.username = nv.replace(/[^a-zA-Z0-9\s]/g, "");
            }
        }
    }
</script>
<style>
    .login-head{
        margin-bottom: 20px;
    }
    .field-phone .van-dropdown-menu .van-dropdown-menu__bar {
        width: 70px !important;
        height: 30px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #cccccc;
    }

    .field-phone .van-dropdown-menu .van-dropdown-menu__item {
        /*border-radius: 5px;*/
    }

    .field-phone .van-dropdown-menu .van-overlay {
        background-color: unset;
        padding: 10px;
    }

    .field-phone .van-dropdown-menu .van-cell {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 0.24155rem 0.38647rem;
        overflow: hidden;
        color: #FFFFFF;
        font-size: 0.33816rem;
        line-height: 0.57971rem;
        background-color: #069150;
        margin-bottom: 0px;
    }

    .field-phone .van-dropdown-menu .van-cell:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .field-phone .van-dropdown-menu .van-cell:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .field-phone .van-dropdown-menu .van-popup {
        border-radius: 5px 5px;
        background-color: #069150;
    }

    .field-phone .van-dropdown-menu__title::after {
        border-color: transparent transparent #000000 #000000;
    }

    .field-phone .van-icon {
        font-size: 20px;
    }

    /*.field-phone .van-dropdown-menu .van-dropdown-item__content {*/
    /*    padding: 0px 10px;*/
    /*}*/
</style>

